import React from "react";

function Search() {
  return (
    <svg id="search" width="32" height="32" viewBox="0 0 32 32">
      <g id="g-search">
        <path d="M18.77303,3.21668c-4.28891-4.28891-11.26744-4.28891-15.55635,0s-4.28891,11.26744,0,15.55635c4.05024,4.05024,10.49625,4.27074,14.81342,.67129l9.22821,9.22821c.39084,.39084,1.02337,.39084,1.41421,0s.39084-1.02337,0-1.41421l-9.22821-9.22821c3.59945-4.31718,3.37896-10.76318-.67129-14.81342Zm-1.41421,14.14214c-3.50929,3.50929-9.21863,3.50929-12.72792,0-3.50929-3.50929-3.50929-9.21863,0-12.72792s9.21863-3.50929,12.72792,0,3.50929,9.21863,0,12.72792Z" fill="#f2a900" />
      </g>
    </svg>
  );
}

export default Search;
