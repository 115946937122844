import React from "react";

function Question() {
  return (
    <svg x="0" y="0" viewBox="0 0 23.6 37">
      <path d="M10.3 27.8c-.8 0-1.5-.3-2-.9-.4-.4-.5-1.4-.5-1.8V25c-.3-3.8.8-5.9 4.4-8 3.5-2.1 5.3-3.8 5.3-7.2C17.5 6.4 14.8 4 11 4 8.3 4 6.1 5.3 5 7.5c-.2.5-1 1.8-2.3 1.8h-.2C1.7 9.3.8 8.8.4 8 0 7.3-.1 6.5.2 5.8 2 2.1 6 0 11.5 0c7.2 0 12 3.9 12 9.6 0 3.6-1.7 6.2-5.4 8.4-3.6 2.1-5.3 3.7-5.3 7.3 0 .6-.2 1.4-.4 1.6-.5.6-1.3.9-2.1.9zM10.5 37c-1.9 0-3.3-1.4-3.3-3.2s1.5-3.2 3.3-3.2c1.9 0 3.4 1.4 3.4 3.2S12.4 37 10.5 37z"></path>
    </svg>
  );
}

export default Question;
