import React from "react";

function NewsLetter() {
  return (
    <svg viewBox="0 0 100 80">
      <g>
        <path d="M98.07935,5.52594c-1.50891-2.33575-3.79785-4.12506-6.49854-4.97577-1.13214-.35663-2.33484-.55017-3.58081-.55017H12c-1.24597,0-2.44867,.19354-3.58081,.55017C5.71851,1.40088,3.4295,3.19025,1.92065,5.52594c-1.20923,1.87189-1.92065,4.0921-1.92065,6.47406v56c0,6.59998,5.40002,12,12,12H88c6.60004,0,12-5.40002,12-12V12c0-2.38196-.71143-4.60217-1.92065-6.47406Zm-15.26306,2.47406l-32.81628,27.89386L17.18365,8H82.81628Zm9.18372,60c0,2.16821-1.83179,4-4,4H12c-2.16821,0-4-1.83179-4-4V12c0-.39746,.07965-.77649,.19354-1.14209L47.17157,43.98926c.78107,.66388,1.80475,.99579,2.82843,.99579s2.04736-.33191,2.82843-.99579L91.80646,10.85791c.11389,.3656,.19354,.74463,.19354,1.14209v56Z" />
      </g>
    </svg>
  );
}

export default NewsLetter;
