import React from "react";

export default class InstagramVideoView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { instaContent: [], url: this.props.url };
    this._timer = null;
  }
  componentDidMount() {
    if (!window.instgrm) {
      const script = document.createElement("script");
      script.src = "https://platform.instagram.com/en_US/embeds.js";
      script.async = true;
      document.body.appendChild(script);
    }
    this.checkAPI().then(() => this.getInsta());
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.edit) {
      return;
    }
    let url = "";
    if (nextProps.url != this.props.url) {
      this.setState(
        {
          url: nextProps.url
        },
        () => {
          this.checkAPI().then(() => this.getInsta());
        }
      );
    }
  }
  checkAPI() {
    return new Promise((resolve, reject) => {
      (function checkAPI(_this) {
        _this._timer = setTimeout(() => {
          if (window.instgrm) {
            clearTimeout(_this._timer);
            resolve();
          } else {
            checkAPI(_this);
          }
        }, 50);
      })(this);
    });
  }
  getInsta() {
    if (this.state.url == "") return;
    $.ajax({
      url: "https://graph.facebook.com/v21.0/instagram_oembed?url=" + this.state.url + "&access_token=837856693917303|03244a6ea3c5b985b0e822445f8c7329",
      context: document.body
    }).done(data => {
      this.setState({ instaContent: data }, () => {
        if (window.instgrm.Embeds != "undefined") {
          window.instgrm.Embeds.process();
        }
      });
    });
  }
  render() {
    return this.state.instaContent.length == 0 ? (
      <div />
    ) : (
      <div dangerouslySetInnerHTML={{ __html: this.state.instaContent.html }} />
    );
  }
}
