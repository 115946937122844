export default {
  helpers: {
    label: {
      hotel_landingpage: {
        official_text: "Great deals if you use their official website!",
        recommend_text: "Lodgings that tsunagu Japan highly recommend!",
        published: "Publish",
      },
    },
  },
  hello: "Hello world",
  confirm: "confirm",
  none: "none",
  newest_top: "Bài viết mới nhất",
  page_desc:
    "Trang này tổng hợp các bài viết về những điều bạn có thể làm ở Nhật, những thông tin chi tiết về các địa điểm du lịch, các tour được đề xuất, giúp bạn khám phá Nhật Bản một cách trọn vẹn nhất.",
  more_btn: "More",
  partner: "Đối tác",
  video: "Video",
  video_desc:
    "Tổng hợp các video cung cấp thông tin liên quan đến Nhật Bản như ẩm thực, điểm ngắm cảnh, nghệ thuật truyền thống, các hoạt động ngoài trời, mẹo du lịch,...",
  view_videos: "Xem thêm video",
  toc: "Mục lục",
  related_label: "Bài viết liên quan",
  search_in: "tìm kiếm trong",
  sort_by: "Sắp xếp theo khu vực",
  coupon_headline: "Vé giảm giá",
  coupon_desc:
    "Thông tin về các loại vé ưu đãi, giảm giá bạn có thể sử dụng khi mua sắm, đặt phòng khách sạn, hoặc đặt tour du lịch.",
  disclaimer_article:
    "Thông tin trong bài viết được cập nhật tại thời điểm công bố",
  area_desc:
    "Trên trang này, bạn có thể tìm kiếm các bài viết liên quan đến các vùng của Nhật Bản. Bạn chỉ cần nhấp vào vùng muốn tìm hiểu trên bản đồ là có thể di chuyển tới trang danh sách các bài viết liên quan đến vùng đó.",
  clip_desc:
    "Trang này hiển thị theo danh sách các bài viết bạn đã đánh dấu. Bạn hãy tham khảo những bài viết được đánh dấu này trong chuyến du lịch của mình để tận hưởng một chuyến đi thật thoải mái!",
  empty_state:
    "Xin lỗi, chúng tôi chưa có nội dung cho trang này. Chúng tôi liên tục cho ra những nội dung mới, vì vậy hãy theo dõi những thông tin cập nhật! ",
  search_by: "Tìm kiếm theo điểm đến, hoạt động hoặc điểm hấp dẫn ...",
  recommended_area: "Những khu vực khuyến khích",
  view_area: "Xem tất cả các khu vực",
  view_restaurant: "Xem tất cả các nhà hàng",
  travel_tips: "Những mẹo khi du lịch",
  transportation: "Phương tiện đi lại",
  manners: "Cách cư xử",
  budget_travel: "Du lịch tiết kiệm",
  climate: "Khí hậu & thời tiết",
  top_travel: "Hành trình du lịch xếp hạng đầu",
  things: "Ngắm cảnh",
  fooddrink: "Đồ ăn",
  hotels: "Khách sạn",
  shopping: "Mua sắm",
  travel_tip: "Mẹo du lịch",
  in_depth: "Văn hoá Nhật Bản",
  festival: "Lễ hội & Sự kiện",
  view_tips: "Xem tất cả các mẹo",
  tours: {
    recommended: "Recommended Tours",
    currency: "JPY",
    from: "from",
    desc:
      "Trang này giới thiệu thông tin về các tour du lịch bạn có thể trải nghiệm ở Nhật Bản",
    tour: "Các tour du lịch",
    details: "Chi tiết",
  },
  featured_topics: "Chủ đề nổi bật",
  japan: "Nhật Bản",
  latest: "Latest Posts",
  media_partner: "MEDIA PARTNER",
  sushi: "#Sushi",
  onsen: "#Suối nước nóng",
  ramen: "#Mì Ramen",
  activites: "#Hoạt động/Trải nghiệm",
  souvenier: "#Quà lưu niệm",
  beauty: "#Làm đẹp & Thời trang",
  history: "#Lịch sử & Văn hóa",
  hotels_ryokan: "#Khách sạn và Ryokan (nhà trọ kiểu Nhật)",
  popular_restaurants: "Nhà hàng nổi tiếng theo khu vực",
  savor: {
    search_title: "Try Out One of Japan's Most Popular Search Engines!",
    search_results: "%{start} - %{end} of %{all}",
    sort_price: "Giá",
    sort_price_ask: "Low to High",
    sort_price_desc: "High to Low",
    sidebar_headline: "Japanese Restaurant Guide",
    slogan:
      "Savor Japan is an exhaustive restaurant<br />\nguide for restaurants in Japan.",
    tokyo: "Tokyo",
    tokyo_sub: {
      kanagawa: "Kanagawa",
      chiba: "Chiba",
      tochigi: "Tochigi",
      gunma: "Gunma",
      saitama: "Saitama",
      ibaraki: "Ibaraki",
      shizuoka: "Shizuoka",
      yamanashi: "Yamanashi",
    },
    kyoto_sub: {
      kyoto: "Kyoto",
      osaka: "Osaka",
      hyogo: "Hyogo",
      nara: "Nara",
      shiga: "Shiga",
      wakayama: "Wakayama",
      mie: "Mie",
    },
    honshu_sub: {
      miyagi: "Miyagi",
      fukushima: "Fukushima",
      yamagata: "Yamagata",
      iwate: "Iwate",
      akita: "Akita",
      aomori: "Aomori",
      aichi: "Aichi",
      shizuoka: "Shizuoka",
      gifu: "Gifu",
      ishikawa: "Ishikawa",
      nagano: "Nagano",
      niigata: "Niigata",
      toyama: "Toyama",
      fukui: "Fukui",
      yamanashi: "Yamanashi",
      hiroshima: "Hiroshima",
      okayama: "Okayama",
      shimane: "Shimane",
      tottori: "Tottori",
      yamaguchi: "Yamaguchi",
    },
    shikoku_sub: {
      ehime: "Ehime",
      kagawa: "Kagawa",
      kochi: "Kochi",
      tokushima: "Tokushima",
    },
    kyushu_sub: {
      fukuoka: "Fukuoka",
      kagoshima: "Kagoshima",
      kumamoto: "Kumamoto",
      miyazaki: "Miyazaki",
      nagasaki: "Nagasaki",
      oita: "Oita",
      saga: "Saga",
    },
    near_tokyo: "Near Tokyo",
    kyoto: "Kyoto and Osaka area",
    hokkaido: "Hokkaido",
    chubu: "Chubu",
    kanto: "Kanto",
    kansai: "Kansai",
    tohoku: "Tohoku",
    chugoku: "Chugoku",
    northern_honshu: "Tohoku",
    central_honshu: "Chubu",
    western_honshu: "Chugoku",
    shikoku: "Shikoku",
    kyushu: "Kyushu",
    okinawa: "Okinawa",
    learn_more: "learn more",
    show_less: "show less",
    restaurant_search: "Tìm kiếm nhà hàng",
    search_by: "Search by Popular Area",
    no_searchresult:
      "Restaurants were not found. Please try different search criteria.",
    sidebar: {
      s_keyword: "Search by Keywords",
      search: "Tìm kiếm nhà hàng",
      location: "Địa điểm",
      cuisine: "Ẩm thực",
      filter: "Filter",
      budget: "Budget",
      dinner: "Dinner",
      lunch: "Lunch",
      extras: "Extras",
      cc: "Credit Card",
      coupons: "Coupons / Vouchers",
      keywords: "Keywords",
      placeholder_keyword: "e.g. city, station,cuisine or specific restaurant",
      placeholder_city: "e.g. city, landmark",
      placeholder_cuisine: "e.g. sushi, ramen",
    },
    filter: {
      no_smoking: "No Smoking",
      menu: "English menu",
      staff: "English-speaking staff",
      languages_available: "English-speaking staff",
      late_night: "Late-night service",
      late_night_service: "Late-night service",
      diet: "Special dietry requests",
      special_diet: "Special dietry requests",
      western_cutlery: "Western cutlery",
      lunch: "Lunch",
      lunch_menu: "Lunch",
      wifi: "Free Wi-Fi",
      free_wifi_available: "Free Wi-Fi",
      visa: "Visa",
      mastercard: "MasterCard",
      anex: "American Express",
      diners: "Diners Club",
      coupons: "Coupons / Vouchers",
    },
  },
  "hotel-lp": {
    meta_title: "tsunagu Japan's Top Picks for Japanese Hotels & Inns",
    meta_desc:
      "Using our vast network, we have picked out the best Japanese hotels and inns to showcase. Many can’t be found on other websites, so take a look!",
    breadcrumb: "Editorial Picks",
    title_html:
      "tsunagu Japan <br/> editorial department carefully selected!<br/>Special homepage introduction",
    desc_headline: "A noteworthy trip starts with exceptional lodging.",
    desc:
      "Japan has around 50,000 hotels and inns, which makes coming to a decision incredibly hard. Using our vast network, our editorial team has scoured through lodgings all over Japan and picked out a select few to introduce to you! There are several that you won’t find on other websites, so it’s definitely worth a look!",
    starting: "Starting from",
    pickup: "New Pickup",
    website: "Official Website",
    website_sub_html: "Great deals if you use their official website!",
    website_sub_recommend_text: "We highly recommend this lodge!",
    related: "Liên quan",
    hotel_price_tax_html:
      "Excluding taxes and fees <br /> It is a charge for two people in one room.",
    notice: {
      change:
        "The information on this website may change even after the publish date.",
      estimated:
        "The estimated accommodation fee is based on the fee for 1 person renting a room that can accommodate 2 people during the off-season. It includes meals, but does not include consumption tax, bathing tax, or service charges. Depending on factors like how many people use 1 room, the season, the type of room, and the accommodation plan, the overall accommodation fee can differ.",
      communication:
        "Staff members who can communicate in languages other than Japanese may not always be present.",
    },
    features: {
      bar: "Bar",
      parking: "Parking",
      bus: "Pick Up Service",
      wifi: "Wi-Fi",
      onsen: "Onsen (suối nước nóng)",
      smoking: "Smoking Room",
      nosmoking: "Non-smoking Room",
      smokingspace: "Smoking Spaces",
      english: "English OK",
      chinese: "Chinese Ok",
    },
  },
  buttons: {
    "btn-default": "default",
    "btn-cust-blue": "blue",
    "btn-cust-green": "green",
    "book-with": "light orange with shadow",
    "btn-ads": "red ad button",
    "savor-button-booking": "savor button",
    "btn-3d-orange": "orange with shadow",
    "btn-dull-yellow": "dull yellow",
    "btn-2d-orange": "orange",
    "btn-light-orange": "light orange"
  },
  map: {
    search_from: "Search from list",
    headline: "Search from Map",
    information1: "※ Sendai is included in Miyagi",
    information2: "※ Yokohama, Kamakura, Hakone is included in Kanagawa",
    information3: "※ Kanazawa is included in Ishikawa",
    information4: "※ Nagoya is included in Aichi",
    hokkaido: "1. Hokkaido",
    sendai: "5. Sendai",
    tokyo: "13. Tokyo",
    yokohama: "14. Yokohama",
    kamakura: "14. Kamakura",
    hakone: "14. Hakone",
    kanazawa: "17. Kanazawa",
    nagoya: "23. Nagoya",
    kyoto: "25. Kyoto",
    osaka: "29. Osaka",
    fukuoka: "40. Fukuoka",
    okinawa: "Okinawa",
    popular: "Popular cities for tourists",
  },
  "basic-information": {
    name: "Tên",
    hours: "Giờ mở cửa",
    closed: "Ngày nghỉ",
    price: "Giá",
    address: "Địa chỉ",
    access: "Cách đi",
    "url-en": "Trang Web (Tiếng Anh)",
    "url-ja": "Trang web (Tiếng Nhật)",
    "url-zh-hant": "Trang web (Tiếng Trung - phồn thể)",
    "url-zh-hans": "Trang web (Tiếng Trung - giản thể)",
    "url-th": "Trang Web (Tiếng Thái)",
    "url-vi": "Trang web (Tiếng Việt)",
    "url-ko": "Trang web (Tiếng Hàn)",
    "url-id": "Trang web (Tiếng Indonesia)",
    others: "Khác",
    checkin: "Check-in",
    checkout: "Check-out",
  },
  search: {
    title: "Tìm kiếm",
    desc: '%{num} kết quả tìm kiếm cho "%{term}"',
  },
  views: {
    pagination: {
      previous: "",
      next: "",
    },
  },
  rankings: {
    title: "Xếp hạng bài viết",
    desc: "",
  },
  tags: {
    title: "%{param}",
    desc: "",
  },
  area: {
    title: "%{param}",
    desc: "",
    area: "Khu vực",
  },
  article: {
    title: "Danh sách bài viết",
    desc: "",
    translator: "Translator",
    author: "Author",
    travelSite: {
      heading: "Khuyến nghị từ các biên tập viên của chúng tôi",
    },
  },
  hotel: {
    name1: "Agoda",
    short1: "A",
    name2: "Booking.com",
    short2: "B",
    bookWith1: "Đặt phòng với Agoda",
    bookWith2: "Đặt phòng với Booking.com",
    bookWith3: "Đặt phòng với Relux",
  },
  hotelpage: {
    labels: {
      name: "Tên",
      desc: "Miêu tả",
      price: "Giá",
      location: "Địa chỉ",
      reference: "Tham khảo:",
      book: "book at",
      info: "Thông tin",
    },
  },
  source: "Source:",
  sns: {
    share: "Chia sẻ",
    pin: "Ghim",
    tweet: "Tweet",
  },
  lang: {
    en: "Tiếng Anh",
    ja: "Tiếng Nhật",
    "zh-hant": "Tiếng Trung - phồn thể",
    "zh-hans": "Tiếng Trung - giản thể",
    th: "Tiếng Thái",
    vi: "Tiếng Việt",
    ko: "Tiếng Hàn",
    id: "Tiếng Indonesia"
  },
  footer: {
    about: "Giới thiệu về tsunagu Japan",
    tsunagudesc:
      "tsunagu Japan is a platform for sharing travel information for Japan. The information includes trip planning advice, useful tips for staying in Japan, or knowledge about Japanese culture that makes your trip deeply enjoyable. We want to bring all of Japan to you.",
    contact: "Liên hệ",
    login: "Đăng nhập",
    logout: "Đăng xuất",
    company: "Hồ sơ công ty",
    privacy: "Riêng tư",
    terms: "Điều khoản",
    jobs: "Việc làm",
    dmc: "For Travel Agencies",
  },
};
