import React from "react";
import { LazyLoad, forceCheck } from "react-lazyload";
import dayjs from "dayjs";
import link_path from "../shared_components/link_path";

class ArticleItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //forceCheck();
  }
  getFormattedDate() {
    let d = this.props.elements.published_at;
    let date = dayjs(this.props.elements.published_at).format("MMM DD, YYYY");
    const { lang = "en" } = this.props;
    if (this.props.elements.schedule[lang]) {
      d = this.props.elements.schedule[lang];
      date = dayjs(d).format("MMM DD, YYYY");
    }
    return <span>{date}</span>;
  }
  getText(str) {
    if (typeof str != "undefined" && str != null && str.length > 90) {
      return str.substr(0, 80) + "...";
    }
    return str;
  }
  renderArticleListSub() {
    return this.props.elements.prefecture ? (
      <span style={{ display: "flex", justifyContent: "space-between" }}>
        <span className="articleList_area">
          {this.props.elements.prefecture}
        </span>
        <span className="articleList_date">{this.getFormattedDate()}</span>
      </span>
    ) : (
      <>
        <span className="articleList_date">{this.getFormattedDate()}</span>
        <span className="articleList_author">
          {this.props.elements.cached_users.username}
        </span>
      </>
    );
  }
  render() {
    let classes = "articleList_item col-xs-12 col-sm-4";
    if (this.props.type === "articleBlock") {
      classes = this.props.isPc
        ? "col-sm-4 articleList_item article_block_item"
        : "articleList_item swiper-slide";
    }

    return (
      <article className={classes}>
        <a
          href={link_path(this.props.articlePath, [
            { key: ":param", value: this.props.elements.slug }
          ])}
          id={this.props.elements.id}
        >
          <div className="articleList_thumbnail">
            <LazyLoad height={300} offset={150}>
              <img
                src={this.props.elements.thumb_url + "&d=400x400"}
                alt={this.props.elements.disp_title}
              />
            </LazyLoad>
          </div>
          <div className="articleList_text">
            <p className="articleList_sub">{this.renderArticleListSub()}</p>
            <h3 className="articleList_title">
              {this.getText(this.props.elements.disp_title)}
            </h3>
          </div>
        </a>
      </article>
    );
  }
}
export default ArticleItem;
