import React from "react";

function Clip() {
  return (
    <svg viewBox="0 0 50 56">
      <g>
        <path d="M46.74037,27.73549l-19.86404,19.85013c-1.27979,1.2796-2.74553,2.23109-4.32065,2.86542-2.35174,.95149-4.94413,1.1921-7.4162,.71089-2.47207-.48121-4.82381-1.66238-6.73802-3.58724-1.27979-1.2796-2.23142-2.74511-2.86585-4.32-.95164-2.35139-1.19228-4.94339-.72193-7.40415,.48129-2.4717,1.66263-4.82309,3.58778-6.73701L30.62819,6.912c.77662-.77651,1.67357-1.35615,2.6252-1.74987,1.43292-.57965,3.00805-.73276,4.51754-.43747s2.93148,1.01711,4.10188,2.18734c.77662,.77651,1.35635,1.67332,1.75014,2.62481,.57973,1.43271,.73287,3.00759,.43753,4.51686s-1.01726,2.93104-2.18767,4.10127l-21.55948,21.55625c-.27346,.27342-.59067,.48121-.91882,.61246-.50316,.2078-1.06102,.25154-1.58606,.15311-.52504-.10937-1.0282-.34998-1.43292-.76557-.27346-.27342-.48129-.59058-.61255-.91868-.20783-.50309-.25158-1.06086-.15314-1.58582,.09844-.52496,.35003-1.02805,.76568-1.43271l19.1968-19.19392-3.23775-3.23727L13.12683,32.52577c-.71099,.71089-1.25791,1.55301-1.61888,2.43888-.53598,1.33428-.67818,2.78886-.40472,4.17782,.26252,1.38896,.95164,2.73418,2.02359,3.80597,.71099,.71089,1.55324,1.25772,2.43925,1.61863,1.33448,.5359,2.78928,.67808,4.17845,.40466,1.38917-.26248,2.73459-.95149,3.80655-2.02329l21.55948-21.55625c1.21416-1.21398,2.14392-2.63575,2.75646-4.14501,.91882-2.2639,1.14853-4.7356,.68911-7.10886-.45941-2.36233-1.60794-4.63716-3.44558-6.47453-1.21416-1.21397-2.63615-2.13266-4.14564-2.74511C38.70069,0,36.22862-.22967,33.855,.22967c-2.36268,.45934-4.63786,1.6077-6.4755,3.44506L5.16371,25.88719c-1.71732,1.71706-3.01898,3.70755-3.87218,5.8402C.00081,34.90997-.32734,38.38785,.32897,41.72354c.64536,3.3357,2.26424,6.52922,4.84569,9.11028,1.71732,1.71706,3.7081,3.01853,5.84108,3.87159,3.18306,1.29053,6.67239,1.61863,10.00859,.97337,3.3362-.64527,6.53019-2.2639,9.11164-4.84496l19.86404-19.86106-3.25963-3.23726Z" />
      </g>
    </svg>
  );
}

export default Clip;
