const BUTTON_TYPES = [
  "btn-default",
  "btn-cust-blue",
  "btn-cust-green",
  "book-with",
  "btn-ads",
  "savor-button-booking",
  "btn-3d-orange",
  "btn-dull-yellow",
  "btn-2d-orange",
  "btn-light-orange"
];
export default BUTTON_TYPES;
