import React from "react";

function Lang() {
  return (
    <svg viewBox="0 0 234 234">
      <circle cx="117" cy="117" r="107" className="st0"></circle>
      <path
        d="M126.4 10.6c15.8 19.6 35.2 48.9 35.2 104.5s-19.4 88.8-35.2 108.4M107.6 10.6C91.8 30.2 72.4 59.5 72.4 115s19.4 88.8 35.2 108.4"
        className="st0"
      ></path>
      <g>
        <path d="M224 153.1L10 153.1" className="st0"></path>
        <path d="M224 80.9L10 80.9" className="st0"></path>
      </g>
    </svg>
  );
}

export default Lang;
