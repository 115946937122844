export default {
  helpers: {
    label: {
      hotel_landingpage: {
        official_text: "官方網站更超值!",
        recommend_text: "tsunagu Japan特別介紹的旅宿！",
      },
    },
  },
  hello: "Hello world",
  toc: "目录",
  partner: "合作伙伴",
  japan: "日本",
  newest_top: "最新攻略",
  page_desc:
    "此页面收集日本旅行必玩攻略。包含旅游景点和区域推荐，让你充分享受日本旅程。",
  more_btn: "More",
  related_label: "相关攻略",
  confirm: "确定",
  none: "皆不適用",
  video: "视频",
  video_desc:
    "这里汇集了日本美食、绝景、文化、传统艺术、活动、小百科等方方面面的视频。",
  view_videos: "观看全部视频",
  coupon_headline: "优惠券",
  coupon_desc: "购物，订酒店，预约当地体验时可以用的优惠券大合集",
  search_in: "搜尋",
  disclaimer_article: "本文提供信息均以公开时为准。",
  latest: "最新文章",
  tours: {
    recommended: "Recommended Tours",
    currency: "JPY",
    from: "from",
    desc: "能在日本进行体验的行程介绍",
    tour: "推荐行程",
    details: "行程详情",
  },
  things: "体验日本",
  fooddrink: "美食",
  hotels: "住宿",
  area_desc:
    "您能在此页面搜寻日本各地精选文章。从地图上点选欲知区域，即可导入当地文章一览。",
  clip_desc:
    "此页面为您钉选的文章一览。协助您一边参考文章，一边来趟得心应手的旅程。",
  empty_state:
    "抱歉，我们目前尚无此类文章！但我们不断致力于分享日本最新消息，还请您持续追踪我们发布的最新资讯！",
  shopping: "购物",
  in_depth: "深度日本",
  travel_tip: "旅行攻略",
  search_by: "搜寻目的地丶活动丶旅游景点",
  recommended_area: "推荐地区",
  view_area: "查看所有区域",
  travel_tips: "实用资讯",
  transportation: "交通",
  manners: "礼仪",
  budget_travel: "低预算旅行",
  climate: "天气",
  top_travel: "热门旅游路线",
  festival: "节庆活动",
  view_tips: "查看所有实用资讯",
  media_partner: "合伙媒体",
  featured_topics: "精选主题",
  sushi: "#寿司",
  onsen: "#温泉",
  ramen: "#拉面",
  activites: "#活动体验",
  souvenier: "#伴手礼",
  beauty: "#时尚美容",
  history: "#历史文化",
  hotels_ryokan: "#酒店住宿",
  popular_restaurants: "热门景点餐厅",
  view_restaurant: "查看所有餐厅",
  savor: {
    search_title: "搜索日本优质餐厅",
    search_results: "%{all} 间餐厅中的 %{start} - %{end} 间",
    sort_price: "价格",
    sort_price_ask: "升序",
    sort_price_desc: "降序",
    learn_more: "了解更多",
    show_less: "关闭",
    sidebar_headline: " ",
    slogan:
      "风味日本是信息详实的日本餐馆指南。<br />如您是访日外国游客，就来对地方了！",
    tokyo: "东京",
    tohoku: "东北",
    kanto: "关东",
    chubu: "中部",
    kansai: "关西",
    chugoku: "中国",
    tokyo_sub: {
      kanagawa: "神奈川",
      chiba: "千叶",
      tochigi: "栃木",
      gunma: "群马",
      saitama: "埼玉",
      ibaraki: "茨城",
      shizuoka: "静冈",
      yamanashi: "山梨",
    },
    kyoto_sub: {
      kyoto: "京都",
      osaka: "大阪",
      hyogo: "兵库",
      nara: "奈良",
      shiga: "滋贺",
      wakayama: "和歌山",
      mie: "三重",
    },
    honshu_sub: {
      miyagi: "宫城",
      fukushima: "福岛",
      yamagata: "山形",
      iwate: "岩手",
      akita: "秋田",
      aomori: "青森",
      aichi: "爱知",
      shizuoka: "静冈",
      gifu: "岐阜",
      ishikawa: "石川",
      nagano: "长野",
      niigata: "新泻",
      toyama: "富山",
      fukui: "福井",
      yamanashi: "山梨",
      hiroshima: "广岛",
      okayama: "冈山",
      shimane: "岛根",
      tottori: "鸟取",
      yamaguchi: "山口",
    },
    shikoku_sub: {
      ehime: "爱媛",
      kagawa: "香川",
      kochi: "高知",
      tokushima: "德岛",
    },
    kyushu_sub: {
      fukuoka: "福冈",
      kagoshima: "鹿儿岛",
      kumamoto: "熊本",
      miyazaki: "宫崎",
      nagasaki: "长崎",
      oita: "大分",
      saga: "佐贺",
    },
    near_tokyo: "东京周边地区",
    kyoto: "京都和大阪地区",
    hokkaido: "北海道",
    northern_honshu: "本州北部（东北地区）",
    central_honshu: "本州中部（中部地区）",
    western_honshu: "本州西部（中国地区）",
    shikoku: "四国",
    kyushu: "九州",
    okinawa: "冲绳和东南部岛屿",
    search_by: "从人气景点搜寻",
    restaurant_search: "搜寻餐厅",
    no_searchresult: "找不到您要的餐厅，请更改搜寻标准。",
    sidebar: {
      s_keyword: "以关键字搜寻",
      search: "搜寻餐厅",
      location: "地点",
      cuisine: "料理",
      filter: "进阶搜寻",
      budget: "预算",
      dinner: "晚餐",
      lunch: "午餐",
      extras: "额外内容",
      cc: "信用卡种类",
      coupons: "优惠券",
      keywords: "关键词",
      placeholder_keyword: "（例）城市、车站、料理或具体餐厅",
      placeholder_city: "（例）城市标志、地标",
      placeholder_cuisine: "（例）喜欢的菜肴",
    },
    filter: {
      no_smoking: "中文（简体字）菜单",
      menu: "中文工作人员",
      staff: "料理特殊要求",
      languages_available: "料理特殊要求",
      late_night: "吸烟区",
      late_night_service: "吸烟区",
      diet: "包间",
      special_diet: "包间",
      western_cutlery: "午餐",
      lunch: "打包",
      lunch_menu: "打包",
      wifi: "免费WiFi",
      free_wifi_available: "免费WiFi",
      visa: "Visa",
      mastercard: "MasterCard",
      anex: "American Express",
      diners: "Diners Club",
      coupons: "优惠券",
    },
  },
  buttons: {
    "btn-default": "default",
    "btn-cust-blue": "blue",
    "btn-cust-green": "green",
    "book-with": "light orange with shadow",
    "btn-ads": "red ad button",
    "savor-button-booking": "savor button",
    "btn-3d-orange": "orange with shadow",
    "btn-dull-yellow": "dull yellow",
    "btn-2d-orange": "orange",
    "btn-light-orange": "light orange"
  },
  "basic-information": {
    name: "名称",
    hours: "营业时间",
    closed: "公休日",
    price: "价位",
    address: "地址",
    access: "交通方式",
    "url-en": "网站(英文)",
    "url-ja": "网站(日文)",
    "url-zh-hant": "网站(繁体字)",
    "url-zh-hans": "网站(简体字)",
    "url-th": "网站(泰语)",
    "url-vi": "网站(越南语)",
    "url-ko": "网站(韩语)",
    "url-id": "网站(印尼语)",
    checkin: "Check-in",
    checkout: "Check-out",
    others: "其它",
  },
  map: {
    headline: "Search from Map",
    information1: "※ Sendai is included in Miyagi",
    information2: "※ Yokohama, Kamakura, Hakone is included in Kanagawa",
    information3: "※ Kanazawa is included in Ishikawa",
    information4: "※ Nagoya is included in Aichi",
    hokkaido: "1. Hokkaido",
    sendai: "5. Sendai",
    tokyo: "13. Tokyo",
    yokohama: "14. Yokohama",
    kamakura: "14. Kamakura",
    hakone: "14. Hakone",
    kanazawa: "17. Kanazawa",
    nagoya: "23. Nagoya",
    kyoto: "25. Kyoto",
    osaka: "29. Osaka",
    fukuoka: "40. Fukuoka",
    okinawa: "沖繩",
    popular: "Popular cities for tourists",
  },
  search: {
    title: "检索",
    desc: "%{num}个关于%{term}的检索结果",
  },
  views: {
    pagination: {
      previous: "",
      next: "",
    },
  },
  rankings: {
    title: "热门文章",
    desc: "",
  },
  tags: {
    title: "%{param}",
    desc: "",
  },
  area: {
    title: "%{param}",
    desc: "",
    area: "区域",
  },
  article: {
    title: "文章列表",
    desc: "",
    translator: "Translator",
    author: "作者",
    travelSite: {
      heading: "我们编辑的推荐",
    },
  },
  hotel: {
    name1: "Agoda",
    short1: "A",
    name2: "Booking.com",
    short2: "B",
    bookWith1: "用Agoda预约",
    bookWith2: "用Booking预约",
    bookWith3: "用Relux预约",
  },
  "hotel-lp": {
    title_html:
      "tsunagu Japan <br/> editorial department carefully selected!<br/>Special homepage introduction",
    desc_headline: "特別的旅行，就從特別的住宿開始！",
    desc:
      "日本據說約有5萬間的酒店及旅館，想要從中挑選其一實在很困難。在此，tsunagu Japan編輯部在獨自擁有的全日本住宿資訊平台中，為您嚴選了數一數二的酒店及旅館！尤其是還有一般訂房網所沒有刊登的旅館及酒店，請您無論如何都要參考看看喔！",
    starting: "Starting from",
    pickup: "New Pickup",
    website: "官方網站",
    website_sub_html: "官方網站更超值!",
    website_sub_recommend_text: "tsunagu Japan特別介紹的旅宿！",
    related: "相关文章",
    hotel_price_tax_html:
      "Excluding taxes and fees <br /> It is a charge for two people in one room.",
    notice: {
      change: "本網站刊載資訊可能會有日後更動的情況，建議您事前確認。",
      estimated:
        "此價格為淡季、1泊2食、2人1房（不含消費稅、溫泉稅及服務費）時1人的住宿費用。刊載的房間費用也會因利用人數、季節、客房型態、料理方案等而有所變動。",
      communication: "並非隨時都有會外文的職員常駐。不便之處，敬請見諒。",
    },
    features: {
      bar: "酒吧",
      parking: "酒吧",
      bus: "含接送",
      wifi: "Wi-Fi",
      onsen: "溫泉",
      smoking: "吸菸房",
      nosmoking: "禁菸房",
      smokingspace: "吸菸處",
      english: "可對應英文",
      chinese: "可對應中文",
    },
  },
  hotelpage: {
    labels: {
      name: "饭店名称",
      desc: "描述",
      price: "价格",
      location: "位置",
      reference: "引用",
      book: "book at",
      info: "资讯",
    },
  },
  source: "来源",
  sns: {
    share: "分享",
    pin: "pin",
    tweet: "推特",
  },
  lang: {
    en: "英文",
    ja: "日文",
    "zh-hant": "繁体中文",
    "zh-hans": "简体中文",
    th: "泰文",
    vi: "越南语",
    ko: "韩语",
    id: "印尼语",
  },
  footer: {
    about: "关于tsunagu Japan",
    tsunagudesc:
      "tsunagu Japan是一个日本旅游资讯的分享平台。资讯内容包括旅行计划建议、逗留于日本期间的实用小技巧，以及日本文化的相关知识介绍，让你的旅途更加充实愉快。我们希望能将日本的全貌呈现于你的眼前!",
    contact: "联系我们",
    login: "登录",
    logout: "登出",
    company: "公司简介",
    privacy: "隐私权和保护政策",
    terms: "使用规章",
    jobs: "招聘信息",
    dmc: "For Travel Agencies",
  },
};
