export default {
  hello: "Hello world",
  sort_by: "แบ่งตามพื้นที่",
  things: "สิ่งที่น่าไปสัมผัส",
  search_map: "ค้นหาจากแผนที่",
  search_in: "ค้นหาในหมวดหมู่",
  more_btn: "อ่านเพิ่ม",
  page_desc:
    "หน้านี้ได้รวบรวมบทความเกี่ยวกับสิ่งที่น่าไปสัมผัสที่ประเทศญี่ปุ่น อัดแน่นด้วยข้อมูลดีๆ ที่จะช่วยให้คุณเที่ยวญี่ปุ่นได้แบบเต็มอิ่ม รวมถึงแหล่งท่องเที่ยวเด็ดๆ มากมาย",
  fooddrink: "อาหารการกิน",
  video: "วิดีโอ",
  video_desc:
    "หน้านี้ได้รวบวิดีโอแนะนำเรื่องราวต่างๆ เกี่ยวกับประเทศญี่ปุ่นทั้งอาหารการกิน วิวสวย วัฒนธรรม ศิลปะดั้งเดิม กิจกรรมเด็ดๆ ไปจนถึงเกร็ดความรู้ต่างๆ และอื่นๆ อีกมากมาย!",
  view_videos: "ดูวิดีโอทั้งหมด",
  partner: "พันธมิตรของเรา",
  related_label: "บทความที่เกี่ยวข้อง",
  disclaimer_article: "เนื้อหาในบทความนี้ อัพเดทล่าสุด ณ วันที่เผยแพร่",
  "sns-bot": "ติดตาม tsunagu Japan ช่องทางอื่นได้ที่",
  newest_top: "บทความล่าสุด อัพเดตทุกวัน",
  coupon_headline: "คูปอง, โปรโมชั่น",
  coupon_desc:
    "รับคูปองส่วนลด โปรโมชั่น ช็อปปิ้ง จองโรงแรม ทัวร์ต่างๆ และอื่นๆอีกมากมาย",
  area_desc:
    "คุณสามารถค้นหาบทความเกี่ยวกับทุกภูมิภาคทั่วประเทศญี่ปุ่นได้ที่นี่ ! โปรดคลิกบริเวณที่คุณต้องการค้นหาในแผนที่ แล้วเราจะนำคุณไปยังหน้าเว็บรวมบทความที่เกี่ยวข้องกับภูมิภาคนั้นๆ !",
  clip_desc:
    "หน้าเว็บสำหรับรวบรวมบทความที่คุณ CLIP ไว้อ่านในภายหลัง ขณะท่องเที่ยวก็อย่าลืมแวะมาอ่านอีกครั้ง เพื่อทริปเที่ยวญี่ปุ่นสุดเพอร์เฟ็กต์ของคุณ !",
  empty_state:
    "ขออภัยเป็นอย่างยิ่ง ขณะนี้ยังไม่มีบทความในหัวข้อนี้ Tsunagu Japan จัดทำบทความใหม่สำหรับผู้อ่านทุกท่านอยู่เสมอ โปรดติดตามเพื่อจะได้ไม่พลาดทุกการอัพเดตจากเรา ! ",
  hotels: "ที่พัก",
  confirm: "ตกลง",
  japan: "ญี่ปุ่น",
  toc: "สารบัญ",
  shopping: "ช็อปปิ้ง",
  travel_tip: "เคล็ดลับ",
  none: "ไม่ตรงกับตัวเลือกใดๆ",
  in_depth: "เจาะลึกญี่ปุ่น",
  latest: "บทความล่าสุด",
  media_partner: "สื่อพันธมิตร",
  search_by: "ค้นหาจากสถานที่, กิจกรรม, แหล่งท่องเที่ยว",
  recommended_area: "สถานที่แนะนำ",
  view_area: "ดูจังหวัดทั้งหมด",
  travel_tips: "เคล็ดลับการท่องเที่ยว",
  transportation: "การเดินทาง",
  manners: "ข้อควรปฏิบัติ",
  budget_travel: " เที่ยวราคาประหยัด",
  climate: "สภาพอากาศ",
  top_travel: "แพลนเที่ยวยอดฮิต",
  festival: "งานเทศกาลและอีเวนต์",
  view_tips: "ดูเคล็ดลับทั้งหมด",
  featured_topics: "หัวข้อแนะนำ",
  sushi: "#ซูชิ",
  onsen: "#ออนเซ็น",
  ramen: "#ราเมง",
  activites: "#กิจกรรม/ประสบการณ์",
  souvenier: "#ของฝาก",
  beauty: "#แฟชั่น ความสวยความงาม",
  history: "#ประวัติศาสตร์และวัฒนธรรม",
  hotels_ryokan: "#โรงแรมและเรียวกัง",
  tours: {
    recommended: "Recommended Tours",
    currency: "JPY",
    from: "from",
    desc: "รวมบทความเกี่ยวกับโปรแกรมทัวร์ในประเทศญี่ปุ่น",
    tour: "ทัวร์แนะนำ",
    details: "ข้อมูล",
  },
  popular_restaurants: "ร้านอาหารยอดนิยมตามจังหวัด",
  view_restaurant: "ดูร้านอาหารทั้งหมด",
  helpers: {
    label: {
      hotel_landingpage: {
        official_text: "คุ้มสุดๆ หากติดต่อผ่าน Official Website!",
        recommend_text: "ที่พักแนะนำเป็นพิเศษโดย tsunagu japan !",
      },
    },
  },
  buttons: {
    "btn-default": "default",
    "btn-cust-blue": "blue",
    "btn-cust-green": "green",
    "book-with": "light orange with shadow",
    "btn-ads": "red ad button",
    "savor-button-booking": "savor button",
    "btn-3d-orange": "orange with shadow",
    "btn-dull-yellow": "dull yellow",
    "btn-2d-orange": "orange",
    "btn-light-orange": "light orange"
  },
  savor: {
    search_by: "ค้นหาจากแหล่งท่องเที่ยวยอดนิยม",
    sidebar: {
      s_keyword: "ค้นหาจากคีย์เวิร์ด",
      search: "ค้นหาร้านอาหาร",
      location: "ที่ตั้ง",
      budget: "งบประมาณ",
      extras: "เงื่อนไขอื่นๆ",
      dinner: "อาหารเย็น",
      lunch: "อาหารกลางวัน",
      cuisine: "ประเภทอาหาร",
      placeholder_keyword: "เช่น ชื่อเมือง สถานี อาหาร หรือร้านอาหาร",
      placeholder_city: "e.g. city, landmark",
      placeholder_cuisine: "e.g. sushi, ramen",
    },
    restaurant_search: "ค้นหาร้านอาหาร",
    filter: {
      menu: "มีเมนูภาษาอังกฤษ",
      no_smoking: "ห้ามสูบบุหรี่",
      staff: "มีพนักงานพูดภาษาอังกฤษได้",
      late_night: "ให้บริการช่วงดึก",
      late_night_service: "ให้บริการช่วงดึก",
      diet: "รีเควสต์เมนูพิเศษได้",
      special_diet: "รีเควสต์เมนูพิเศษได้",
      western_cutlery: "ให้บริการช้อนส้อมมีด",
      lunch: "อาหารกลางวัน",
      lunch_menu: "อาหารกลางวัน",
      wifi: "บริการฟรีไวไฟ",
      free_wifi_available: "บริการฟรีไวไฟ",
    },
    sidebar_headline: "Japanese Restaurant Guide",
    slogan:
      "Savor Japan is an exhaustive restaurant<br />\nguide for restaurants in Japan.",
    tokyo: "โตเกียว",
    tohoku: "โทโฮคุ",
    kanto: "คันโต",
    chubu: "ชูบุ",
    kansai: "คันไซ",
    chugoku: "ชูโกคุ",
    tokyo_sub: {
      kanagawa: "คานากาว่า",
      chiba: "ชิบะ",
      tochigi: "โทะชิงิ",
      gunma: "กุนมะ",
      saitama: "ไซตามะ",
      ibaraki: "อิบะระกิ",
      shizuoka: "ชิสึโอกะ",
      yamanashi: "ยามานาชิ",
    },
    kyoto_sub: {
      kyoto: "เกียวโต",
      osaka: "โอซาก้า",
      hyogo: "เฮียวโกะ",
      nara: "นารา",
      shiga: "ชิกะ",
      wakayama: "วาคายามะ",
      mie: "มิเอะ",
    },
    honshu_sub: {
      miyagi: "มิยากิ",
      fukushima: "ฟุคุชิมะ",
      yamagata: "ยามากาตะ",
      iwate: "อิวาเตะ",
      akita: "อาคิตะ",
      aomori: "อาโอโมริ",
      aichi: "ไอจิ",
      shizuoka: "ชิสึโอกะ",
      gifu: "กิฟุ",
      ishikawa: "อิชิคาวะ",
      nagano: "นากาโน่",
      niigata: "นีงาตะ",
      toyama: "โทยามะ",
      fukui: "ฟุคุอิ",
      yamanashi: "ยามานาชิ",
      hiroshima: "ฮิโรชิม่า",
      okayama: "โอคายาม่า",
      shimane: "ชิมาเนะ",
      tottori: "ทตโตะริ",
      yamaguchi: "ยามากุจิ",
    },
    shikoku_sub: {
      ehime: "เอฮิเมะ",
      kagawa: "คากาวะ",
      kochi: "โคจิ",
      tokushima: "โทคุชิมะ",
    },
    kyushu_sub: {
      fukuoka: "ฟุกุโอกะ",
      kagoshima: "คาโกชิม่า",
      kumamoto: "คุมาโมโต้",
      miyazaki: "มิยาซากิ",
      nagasaki: "นางาซากิ",
      oita: "โออิตะ",
      saga: "ซากะ",
    },
    near_tokyo: "ใกล้โตเกียว",
    kyoto: "เกียวโตและโอซาก้า",
    hokkaido: "ฮอกไกโด",
    northern_honshu: "โทโฮคุ",
    central_honshu: "ชูบุ)",
    western_honshu: "ชูโกคุ",
    shikoku: "ชิโคคุ",
    kyushu: "คิวชู",
    okinawa: "โอกินาว่า",
  },
  "hotel-lp": {
    title_html:
      "tsunagu Japan <br/> editorial department carefully selected!<br/>Special homepage introduction",
    desc_headline: "ทริปพิเศษ กับที่พักสุดพิเศษ",
    desc:
      "ที่ญี่ปุ่นนั้นมีโรงแรมหรือเรียวกังที่พักมากกว่า 5 หมื่นแห่ง จะให้เลือกจากทั้งหมดก็คงลำบากน่าดู ดังนั้นทีมงาน tsunagu japan ซึ่งมีคอนเน็คชั่นกับโรงแรมมากมายทั่วญี่ปุ่น จึงได้ทำการคัดสรรโรงแรมกับเรียวกังพิเศษ ให้เหลือเพียงไม่กี่แห่ง แนะนำให้คุณโดยเฉพาะ! โรงแรมบางแห่งค้นหาข้อมูลผ่านทางอินเทอร์เน็ตไม่ได้ด้วยนะ ฉะนั้นพลาดไม่ได้แล้ว!",
    starting: "Starting from",
    pickup: "New Pickup",
    website: "Official Website",
    website_sub_html: "คุ้มสุดๆ หากติดต่อผ่าน Official Website !",
    website_sub_recommend_text: "ที่พักแนะนำเป็นพิเศษโดย tsunagu japan !",
    related: "บทความที่เกี่ยวข้อง",
    hotel_price_tax_html:
      "Excluding taxes and fees <br /> It is a charge for two people in one room.",
    notice: {
      change:
        "ข้อมูลบนเว็บไซต์นี้ อาจมีการเปลี่ยนแปลงในภายหลัง กรุณาตรวจสอบอีกครั้ง",
      estimated:
        "ค่าใช้จ่ายในการเข้าพักต่อ 1 ท่าน สำหรับช่วงนอกฤดูแช่ออนเซ็น ผู้เข้าพักเป็นผู้ใหญ่ 2 ท่าน พักรวมในหนึ่งห้อง 1 คืนพร้อมอาหารสองมื้อ (ราคานี้ยังไม่รวม ภาษี VAT ภาษีแช่ออนเซ็น และค่าบริการ) ทั้งนี้ค่าใช้จ่ายจะเปลี่ยนไปตามจำนวนคนที่พักรวมในหนึ่งห้อง ฤดูกาล ชนิดของห้องและคอร์สอาหาร ฯลฯ",
      communication:
        "โปรดทราบว่าพนักงานที่สามารถพูดภาษาอื่นนอกจากภาษาญี่ปุ่นได้อาจมิได้ประจำหน้าที่ตลอด ",
    },
    features: {
      bar: "บาร์",
      parking: "ที่จอดรถ",
      bus: "บริการรับ-ส่ง",
      wifi: "Wi-Fi",
      onsen: "ออนเซ็น",
      smoking: "ห้องสูบบุหรี่ได้",
      nosmoking: "ห้องปลอดบุหรี่",
      smokingspace: "พื้นที่สำหรับสูบบุหรี่",
      english: "ภาษาอังกฤษ-ได้",
      chinese: "ภาษาไทย-ได้",
    },
  },
  map: {
    search_from: "ค้นหาจากรายชื่อจังหวัด",
    headline: "ค้นหาจากแผนที่",
    information1: "※ เมืองเซนไดอยู่ในจังหวัดมิยากิ",
    information2: "※ โยโกฮาม่า คามาคุระ และฮาโกเน่ อยู่ในจังหวัดคานางาวะ",
    information3: "※ เมืองคานาซาว่าอยู่ในจังหวัดอิชิคาวะ",
    information4: "※ เมืองนาโกย่าอยู่ในจังหวัดไอจิ",
    hokkaido: "1. ฮอกไกโด",
    sendai: "5. เซนได",
    tokyo: "13. โตเกียว",
    yokohama: "14. โยโกฮาม่า",
    kamakura: "14. คามาคุระ",
    hakone: "14. ฮาโกเน่",
    kanazawa: "17. คานาซาว่า ",
    nagoya: "23. นาโกย่า",
    kyoto: "25. เกียวโต",
    osaka: "29. โอซาก้า",
    fukuoka: "40. ฟูกุโอกะ",
    okinawa: "โอกินาว่า",
    popular: "เมืองท่องเที่ยวยอดนิยม",
  },
  "basic-information": {
    name: "ชื่อ",
    hours: "เวลาทำการ",
    closed: "วันหยุดทำการ",
    price: "ราคา",
    address: "ที่อยู่",
    access: "การเดินทาง",
    "url-en": "เว็บไซต์ (อังกฤษ)",
    "url-ja": "เว็บไซต์ (ญี่ปุ่น)",
    "url-zh-hant": "เว็บไซต์ (ภาษาจีนตัวเต็ม)",
    "url-zh-hans": "เว็บไซต์ (ภาษาจีนตัวย่อ)",
    "url-th": "เว็บไซต์ (ไทย)",
    "url-vi": "เว็บไซต์ (เวียดนาม)",
    "url-ko": "เว็บไซต์ (เกาหลี)",
    "url-id": "เว็บไซต์ (อินโดนีเซีย)",
    others: "อื่นๆ",
    checkin: "Check-in",
    checkout: "Check-out",
  },
  search: {
    title: "ค้นหา",
    desc: "%{num} ผลลัพธ์การค้นหาสำหรับ %{term}",
  },
  views: {
    pagination: {
      previous: "",
      next: "",
    },
  },
  rankings: {
    title: "บทความยอดนิยม",
    desc: "",
  },
  tags: {
    title: "%{param}",
    desc: "",
  },
  area: {
    title: "%{param}",
    desc: "",
    area: "ค้นหาตามจังหวัด",
  },
  article: {
    title: "รายการบทความ",
    desc: "",
    translator: "Translator",
    author: "Author",
    travelSite: {
      heading: "ข้อมูลท่องเที่ยว สินค้า กิจกรรมแนะนำในญี่ปุ่นจากคนที่อยู่จริง",
    },
  },
  hotel: {
    name1: "Agoda",
    short1: "A",
    name2: "Booking.com",
    short2: "B",
    bookWith1: "จองผ่าน Agoda",
    bookWith2: "จองผ่าน Booking.com",
    bookWith3: "จองผ่าน Relux",
  },
  hotelpage: {
    labels: {
      name: "ชื่อที่พัก",
      desc: "รายละเอียด",
      price: "ราคา",
      location: "ที่อยู่",
      reference: "อ้างอิง",
      book: "book at",
      info: "ข้อมูล",
    },
  },
  source: "แหล่งที่ี่มา:",
  sns: {
    share: "share",
    pin: "pin",
    tweet: "tweet",
  },
  lang: {
    en: "ภาษาอังกฤษ",
    ja: "ภาษาญี่ปุ่น",
    "zh-hant": "ภาษาจีน (ตัวเต็ม)",
    "zh-hans": "ภาษาจีีน (ตัวย่อ)",
    th: "ภาษาไทย",
    vi: "เวียดนาม",
    ko: "เกาหลี",
    id: "อินโดนีเซีย",
  },
  footer: {
    about: "เกี่ยวกับ tsunagu Japan",
    tsunagudesc:
      "tsunagu Japan คือแพลตฟอร์มแสดง ข้อมูลสำหรับนักท่องเที่ยวที่จะมาเที่ยวญี่ปุ่น เราจัดสรรข้อมูลต่างๆ อาทิเช่น ที่ท่องเที่ยว อาหารแนะนำ ย่านช็อปปิ้ง และวิธีเดินทาง เพื่อให้ทุกท่านได้สัมผัสกับประสบการณ์ที่ ยอดเยี่ยมที่ญี่ปุ่นแห่งนี้",
    contact: "ติดต่อเรา",
    login: "ล็อกอิน",
    logout: "ล็อกเอาท์",
    company: "เกี่ยวกับบริษัท",
    privacy: "นโยบายข้อมูลส่วนบุคคล",
    terms: "เงื่อนไขการใช้บริการ",
    jobs: "งาน",
    dmc: "For Travel Agencies",
  },
};
