export default {
  helpers: {
    label: {
      hotel_landingpage: {
        official_text: "Great deals if you use their official website!",
        recommend_text: "Lodgings that tsunagu Japan highly recommend!",
        published: "Publish",
      },
    },
  },
  hello: "Hello world",
  confirm: "Confirm",
  js: {
    hello: "cool",
  },
  partner: "パートナー",
  video: "Videos",
  video_desc:
    "This page shows our handpicked collection of videos on everything related to Japan. Food, scenic spots, traditional arts, outdoor activities, basic trivia, and more await!",
  page_desc:
    "This page collects articles on things to do in Japan. It's full of information that helps you enjoy Japan, including recommended tourist spots, places",
  more_btn: "More",
  disclaimer_article:
    "The information in this article is accurate at the time of publication.",
  "sns-bot": "tsunagu Japan SNS",
  newest_top: "Newest Articles",
  related_label: "Recommended",
  page_title: "tsunagu Japan",
  page_prefix: "tsunagu Japan",
  sort_by: "Sort by area",
  none: "none",
  search_map: "Search from Map",
  search_in: "Search in",
  area_desc:
    "This page lets you search for articles by area. If you click on an area in the map, a new page containing articles associated with that area will pop up.",
  view_coupons: "View all coupons",
  view_videos: "View all Videos",
  coupon_headline: "Coupons",
  coupon_desc:
    "This page lists a variety of discount coupons that you can use for all kinds of situations, from shopping to booking hotels or tours.",
  new_arrivals: "New Arrivals",
  clips_title: "Your Clip",
  clip_desc:
    "This page shows all the articles on this site that you've bookmarked. Please use these articles to have an even more wonderful trip to Japan!",
  empty_state:
    "Sorry, we don't have content for this yet! We're always churning out new content, so please stay tuned for any updates!",
  search_by: "Search by destination, activity, or attraction…",
  recommended_area: "Recommended Areas",
  view_area: "View all areas",
  view_restaurant: "View all restaurants",
  travel_tips: "Travel Tips",
  transportation: "Transportation",
  manners: "Manners",
  budget_travel: "Budget Travel",
  climate: "Climate & Weather",
  top_travel: "Top Travel Itineraries",
  things: "Things to Do",
  fooddrink: "Food & Drink",
  hotels: "Hotels & Ryokan",
  shopping: "Shopping",
  travel_tip: "Travel Tips",
  in_depth: "Japan In-Depth",
  festival: "Festivals & Events",
  view_tips: "View all tips",
  featured_topics: "Featured Topics",
  japan: "Japan",
  latest: "Latest Posts",
  media_partner: "MEDIA PARTNER",
  sushi: "#Sushi",
  onsen: "#Onsen",
  ramen: "#Ramen",
  activites: "#Activities/Experiences",
  souvenier: "#Souvenirs",
  beauty: "#Beauty & Fashion",
  history: "#History & Culture",
  hotels_ryokan: "#Hotels & Ryokan",
  popular_restaurants: "Popular Restaurants by Area",
  tours: {
    recommended: "Recommended Tours",
    currency: "JPY",
    from: "from",
    desc: "This page lists tours in Japan that you can sign up for.",
    tour: "Recommended Tours",
    details: "Details",
  },
  savor: {
    search_title: "Try Out One of Japan's Most Popular Search Engines!",
    search_results: "%{start} - %{end} of %{all}",
    sort_price: "Price",
    sort_price_ask: "Low to High",
    sort_price_desc: "High to Low",
    sidebar_headline: "Japanese Restaurant Guide",
    slogan:
      "Savor Japan is an exhaustive restaurant<br />\nguide for restaurants in Japan.",
    tokyo: "Tokyo",
    tokyo_sub: {
      kanagawa: "Kanagawa",
      chiba: "Chiba",
      tochigi: "Tochigi",
      gunma: "Gunma",
      saitama: "Saitama",
      ibaraki: "Ibaraki",
      shizuoka: "Shizuoka",
      yamanashi: "Yamanashi",
    },
    kyoto_sub: {
      kyoto: "Kyoto",
      osaka: "Osaka",
      hyogo: "Hyogo",
      nara: "Nara",
      shiga: "Shiga",
      wakayama: "Wakayama",
      mie: "Mie",
    },
    honshu_sub: {
      miyagi: "Miyagi",
      fukushima: "Fukushima",
      yamagata: "Yamagata",
      iwate: "Iwate",
      akita: "Akita",
      aomori: "Aomori",
      aichi: "Aichi",
      shizuoka: "Shizuoka",
      gifu: "Gifu",
      ishikawa: "Ishikawa",
      nagano: "Nagano",
      niigata: "Niigata",
      toyama: "Toyama",
      fukui: "Fukui",
      yamanashi: "Yamanashi",
      hiroshima: "Hiroshima",
      okayama: "Okayama",
      shimane: "Shimane",
      tottori: "Tottori",
      yamaguchi: "Yamaguchi",
    },
    shikoku_sub: {
      ehime: "Ehime",
      kagawa: "Kagawa",
      kochi: "Kochi",
      tokushima: "Tokushima",
    },
    kyushu_sub: {
      fukuoka: "Fukuoka",
      kagoshima: "Kagoshima",
      kumamoto: "Kumamoto",
      miyazaki: "Miyazaki",
      nagasaki: "Nagasaki",
      oita: "Oita",
      saga: "Saga",
    },
    near_tokyo: "Near Tokyo",
    kyoto: "Kyoto and Osaka area",
    hokkaido: "Hokkaido",
    chubu: "Chubu",
    kanto: "Kanto",
    kansai: "Kansai",
    tohoku: "Tohoku",
    chugoku: "Chugoku",
    northern_honshu: "Nothern Honshu (Tohoku)",
    central_honshu: "Central Honshu (Chubu)",
    western_honshu: "Western Honshu (Chugoku)",
    shikoku: "Shikoku",
    kyushu: "Kyushu",
    okinawa: "Okinawa",
    learn_more: "learn more",
    show_less: "show less",
    restaurant_search: "Restaurant Search",
    search_by: "Search by Popular Area",
    no_searchresult:
      "Restaurants were not found. Please try different search criteria.",
    sidebar: {
      s_keyword: "Search by Keywords",
      search: "Restaurant Search",
      location: "Location",
      cuisine: "Cuisine",
      filter: "Filter",
      budget: "Budget",
      dinner: "Dinner",
      lunch: "Lunch",
      extras: "Extras",
      cc: "Credit Card",
      coupons: "Coupons / Vouchers",
      keywords: "Keywords",
      placeholder_keyword: "e.g. city, station,cuisine or specific restaurant",
      placeholder_city: "e.g. city, landmark",
      placeholder_cuisine: "e.g. sushi, ramen",
    },
    filter: {
      no_smoking: "No Smoking",
      menu: "English menu",
      staff: "English-speaking staff",
      languages_available: "English-speaking staff",
      late_night: "Late-night service",
      late_night_service: "Late-night service",
      diet: "Special dietry requests",
      special_diet: "Special dietry requests",
      western_cutlery: "Western cutlery",
      lunch: "Lunch",
      lunch_menu: "Lunch",
      wifi: "Free Wi-Fi",
      free_wifi_available: "Free Wi-Fi",
      visa: "Visa",
      mastercard: "MasterCard",
      anex: "American Express",
      diners: "Diners Club",
      coupons: "Coupons / Vouchers",
    },
  },
  "hotel-lp": {
    meta_title: "tsunagu Japan's Top Picks for Japanese Hotels & Inns",
    meta_desc:
      "Using our vast network, we have picked out the best Japanese hotels and inns to showcase. Many can’t be found on other websites, so take a look!",
    breadcrumb: "Editorial Picks",
    title_html:
      "tsunagu Japan <br/> editorial department carefully selected!<br/>Special homepage introduction",
    desc_headline: "A noteworthy trip starts with exceptional lodging.",
    desc:
      "Japan has around 50,000 hotels and inns, which makes coming to a decision incredibly hard. Using our vast network, our editorial team has scoured through lodgings all over Japan and picked out a select few to introduce to you! There are several that you won’t find on other websites, so it’s definitely worth a look!",
    starting: "Starting from",
    pickup: "New Pickup",
    website: "Official Website",
    website_sub_html: "Great deals if you use their official website!",
    website_sub_recommend_text: "We highly recommend this lodge!",
    related: "関連記事",
    hotel_price_tax_html:
      "Excluding taxes and fees <br /> It is a charge for two people in one room.",
    notice: {
      change:
        "The information on this website may change even after the publish date.",
      estimated:
        "The estimated accommodation fee is based on the fee for 1 person renting a room that can accommodate 2 people during the off-season. It includes meals, but does not include consumption tax, bathing tax, or service charges. Depending on factors like how many people use 1 room, the season, the type of room, and the accommodation plan, the overall accommodation fee can differ.",
      communication:
        "Staff members who can communicate in languages other than Japanese may not always be present.",
    },
    features: {
      bar: "Bar",
      parking: "Parking",
      bus: "Pick Up Service",
      wifi: "Wi-Fi",
      onsen: "Hot Spring",
      smoking: "Smoking Room",
      nosmoking: "Non-smoking Room",
      smokingspace: "Smoking Spaces",
      english: "English OK",
      chinese: "Chinese Ok",
    },
  },
  buttons: {
    "btn-default": "default",
    "btn-cust-blue": "blue",
    "btn-cust-green": "green",
    "book-with": "light orange with shadow",
    "btn-ads": "red ad button",
    "savor-button-booking": "savor button",
    "btn-3d-orange": "orange with shadow",
    "btn-dull-yellow": "dull yellow",
    "btn-2d-orange": "orange",
    "btn-light-orange": "light orange"
  },
  map: {
    search_from: "Search from list",
    headline: "Search from Map",
    information1: "※ Sendai is included in Miyagi",
    information2: "※ Yokohama, Kamakura, Hakone is included in Kanagawa",
    information3: "※ Kanazawa is included in Ishikawa",
    information4: "※ Nagoya is included in Aichi",
    hokkaido: "1. Hokkaido",
    sendai: "5. Sendai",
    tokyo: "13. Tokyo",
    yokohama: "14. Yokohama",
    kamakura: "14. Kamakura",
    hakone: "14. Hakone",
    kanazawa: "17. Kanazawa",
    nagoya: "23. Nagoya",
    kyoto: "25. Kyoto",
    osaka: "29. Osaka",
    fukuoka: "40. Fukuoka",
    okinawa: "Okinawa",
    popular: "Popular cities for tourists",
  },
  "basic-information": {
    name: "名前",
    hours: "営業時間",
    closed: "休業日",
    price: "価格",
    address: "住所",
    access: "アクセス",
    "url-en": "ウェブサイト (英語)",
    "url-ja": "ウェブサイト (日本語)",
    "url-zh-hant": "ウェブサイト (繁体字)",
    "url-zh-hans": "ウェブサイト (簡体字)",
    "url-th": "ウェブサイト (タイ語)",
    "url-ko": "ウェブサイト (韓国語)",
    "url-vi": "ウェブサイト (ベトナム語)",
    "url-id": "ウェブサイト (インドネシア語)",
    others: "その他:",
    checkin: "チェックイン:",
    checkout: "チェックアウト:",

  },
  toc: "Table of Contents",
  search: {
    title: "カテゴリーから探す",
    desc: "%{num} search results found for %{term}",
  },
  views: {
    pagination: {
      previous: "",
      next: "",
    },
  },
  rankings: {
    title: "人気記事",
    desc: "",
  },
  tags: {
    title: "%{param}",
    desc: "This page collects articles about %{tag}",
  },
  area: {
    title: "%{param}",
    desc: "",
    area: "エリア",
  },
  article: {
    title: "記事一覧",
    desc: "",
    translator: "Translator",
    author: "Author",
    travelSite: {
      heading: "編集部お勧めの旅館・商品・体験コンテンツ",
    },
  },
  hotel: {
    name1: "Agoda",
    short1: "A",
    name2: "Booking.com",
    short2: "B",
    bookWith1: "Agodaで予約",
    bookWith2: "Booking.comで予約",
    bookWith3: "Reluxで予約",
  },
  hotelpage: {
    labels: {
      name: "ホテル名",
      desc: "詳細",
      price: "価格",
      location: "住所",
      reference: "出典",
      book: "book at",
      info: "情報",
    },
  },
  source: "出典:",
  sns: {
    share: "share",
    pin: "pin",
    tweet: "tweet",
  },
  lang: {
    en: "英語",
    ja: "日本語",
    "zh-hant": "繁体字",
    "zh-hans": "簡体字",
    th: "タイ語",
    vi: "ベトナム語",
    ko: "韓国語",
    id: "インドネシア語",
  },
  footer: {
    about: "tsunagu Japanとは",
    tsunagudesc:
      "tsunagu Japanは訪日旅行者のための情報プラットフォームです。訪日旅行者が日本での旅行体験を素晴らしいものにしてもらうために、観光、グルメ、ショッピング、ハウツーなど様々な情報を発信しています。",
    contact: "問い合わせ",
    login: "ログイン",
    logout: "ログアウト",
    company: "会社概要",
    privacy: "プライバシーポリシー",
    terms: "利用規約",
    jobs: "採用情報",
    dmc: "For Travel Agencies",
  },
};
